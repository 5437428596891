(function ($) {
    'use_strict';

    $(document).ready(function () {
        $("a[href*='\\#']").on('click', function (e) {
 
            

            // store hash
            var hash = this.hash;  
            if($(hash).length === 0){
                return true; 
            }
            
            // prevent default anchor click behavior
            e.preventDefault();
            

            if(hash == '#kontakta-oss'){
                
                $('.fullscreen-menu').stop().animate({
                    scrollTop: $(hash).offset().top
                }, 500, function () { 
                    window.location.hash = hash; 
                });

            } else{
                $('html, body').stop().animate({
                    scrollTop: $(hash).offset().top
                }, 1500, function () { 


                    // when done, add hash to url
                    // (default click behaviour)
                    window.location.hash = hash; 
                });
            }

        });
    });
    
})(jQuery, window);