/*!--------------------------------------------------------------------
JAVASCRIPT "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
var outdatedBrowser = function(options) {

    var s = this;

    //Variable definition (before ajax)
    s.outdated = document.getElementById("outdated");

    // Default settings
    s.defaultOpts = {
        bgColor: '#f25648',
        color: '#ffffff',
        lowerThan: 'transform',
        languagePath: '../outdatedbrowser/lang/en.html'
    }

    if (options) {
        //assign css3 property or js property to IE browser version
        if (options.lowerThan == 'IE8' || options.lowerThan == 'borderSpacing') {
            options.lowerThan = 'borderSpacing';
        } else if (options.lowerThan == 'IE9' || options.lowerThan == 'boxShadow') {
            options.lowerThan = 'boxShadow';
        } else if (options.lowerThan == 'IE10' || options.lowerThan == 'transform' || options.lowerThan == '' || typeof options.lowerThan === "undefined") {
            options.lowerThan = 'transform';
        } else if (options.lowerThan == 'IE11' || options.lowerThan == 'borderImage') {
            options.lowerThan = 'borderImage';
        }  else if (options.lowerThan == 'Edge' || options.lowerThan == 'js:Promise') {
            options.lowerThan = 'js:Promise';
        }

        //all properties
        s.defaultOpts.bgColor = options.bgColor;
        s.defaultOpts.color = options.color;
        s.defaultOpts.lowerThan = options.lowerThan;
        s.defaultOpts.languagePath = options.languagePath;

        bkgColor = s.defaultOpts.bgColor;
        txtColor = s.defaultOpts.color;
        cssProp = s.defaultOpts.lowerThan;
        languagePath = s.defaultOpts.languagePath;
    } else {
        bkgColor = s.defaultOpts.bgColor;
        txtColor = s.defaultOpts.color;
        cssProp = s.defaultOpts.lowerThan;
        languagePath = s.defaultOpts.languagePath;
    } //end if options


    //Define opacity and fadeIn/fadeOut functions
    var done = true;

    function function_opacity(opacity_value) {
        s.outdated.style.opacity = opacity_value / 100;
        s.outdated.style.filter = 'alpha(opacity=' + opacity_value + ')';
    }

    // function function_fade_out(opacity_value) {
    //     function_opacity(opacity_value);
    //     if (opacity_value == 1) {
    //         outdated.style.display = 'none';
    //         done = true;
    //     }
    // }

    function get_browser() {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR|Edge\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    }


    function function_fade_in(opacity_value) {
        function_opacity(opacity_value);
        if (opacity_value == 1) {
            s.outdated.style.display = 'block';
        }
        if (opacity_value == 100) {
            done = true;
        }
    }

    //check if element has a particular class
    // function hasClass(element, cls) {
    //     return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;
    // }

    var supports = ( function() {
        var div = document.createElement('div');
        var vendors = 'Khtml Ms O Moz Webkit'.split(' ');
        var len = vendors.length;

        return function(prop) {
            if (prop in div.style) return true;

            prop = prop.replace(/^[a-z]/, function(val) {
                return val.toUpperCase();
            });

            while (len--) {
                if (vendors[len] + prop in div.style) {
                    return true;
                }
            }
            return false;
        };
    } )();

    var validBrowser = false;

    // browser check by js props
    if(/^js:+/g.test(cssProp)) {
        var jsProp = cssProp.split(':')[1];
        if(!jsProp)
            return;

        switch (jsProp) {
			case 'Promise':
                validBrowser = window.Promise !== undefined && window.Promise !== null && Object.prototype.toString.call(window.Promise.resolve()) === '[object Promise]';
                break;
            default:
                validBrowser = false;
		}
    } else {
        // check by css3 property (transform=default)
        validBrowser = supports('' + cssProp + '');
    }

    var browser = get_browser();

    if(browser.name == 'Safari' && parseInt(browser.version) < 9){
        validBrowser = false;
    }


	if (!validBrowser ) {
		if (done && s.outdated.style.opacity !== '1') {
            document.body.className += ' ' + 'invalid_browser';
			done = false;
			for (var i = 1; i <= 100; i++) {
				setTimeout((function (x) {
					return function () {
						function_fade_in(x);
					};
				})(i), i * 8);
			}
		}
	} else {

        return;
    } //end if


    //Check AJAX Options: if languagePath == '' > use no Ajax way, html is needed inside <div id="outdated">
    if (languagePath === ' ' || languagePath.length == 0) {
        startStylesAndEvents();
    } else {
        grabFile(languagePath);
    }

    //events and colors
    function startStylesAndEvents() {
        var btnClose = document.getElementById("btnCloseUpdateBrowser");
        // var btnUpdate = document.getElementById("btnUpdateBrowser");

        //check settings attributes
        s.outdated.style.backgroundColor = bkgColor;
        //way too hard to put !important on IE6
        s.outdated.style.color = txtColor;
        s.outdated.children[0].style.color = txtColor;
        s.outdated.children[1].style.color = txtColor;

        //check settings attributes
        // btnUpdate.style.color = txtColor;
        // // btnUpdate.style.borderColor = txtColor;
        // if (btnUpdate.style.borderColor) {
        //     btnUpdate.style.borderColor = txtColor;
        // }
        btnClose.style.color = txtColor;

        //close button
        btnClose.onmousedown = function() {
            console.log('click');
            s.outdated.style.display = 'none';
            s.outdated.className += ' closed';
            document.body.className = document.body.className.replace("invalid_browser", "");
            
            var cookie = new gfCookie();
            cookie.set('outdated_browser','yes', { path : '/' });
            return false;
        };

        //Override the update button color to match the background color
        // btnUpdate.onmouseover = function() {
        //     this.outdated.style.color = bkgColor;
        //     this.outdated.style.backgroundColor = txtColor;
        // };
        // btnUpdate.onmouseout = function() {
        //     this.outdated.style.color = txtColor;
        //     this.outdated.style.backgroundColor = bkgColor;
        // };
    } //end styles and events


    // IF AJAX with request ERROR > insert english default
    var ajaxEnglishDefault = '<h6>Your browser is out-of-date!</h6>'
        + '<p>Update your browser to view this website correctly. <a id="btnUpdateBrowser" href="http://outdatedbrowser.com/">Update my browser now </a></p>'
        + '<p class="last"><button id="btnCloseUpdateBrowser" title="Close">&times;</button></p>';


    //** AJAX FUNCTIONS - Bulletproof Ajax by Jeremy Keith **
    function getHTTPObject() {
        var xhr = false;
        if (window.XMLHttpRequest) {
            xhr = new XMLHttpRequest();
        } else if (window.ActiveXObject) {
            try {
                xhr = new ActiveXObject("Msxml2.XMLHTTP");
            } catch ( e ) {
                try {
                    xhr = new ActiveXObject("Microsoft.XMLHTTP");
                } catch ( e ) {
                    xhr = false;
                }
            }
        }
        return xhr;
    }//end function

    function grabFile(file) {
        var request = getHTTPObject();
        if (request) {
            request.onreadystatechange = function() {
                displayResponse(request);
            };
            request.open("GET", file, true);
            request.send(null);
        }
        return false;
    } //end grabFile

    function displayResponse(request) {
        var insertContentHere = document.getElementById("outdated");
        if (request.readyState == 4) {
            if (request.status == 200 || request.status == 304) {
                insertContentHere.innerHTML = request.responseText;
            } else {
                insertContentHere.innerHTML = ajaxEnglishDefault;
            }
            startStylesAndEvents();
        }
        return false;
    }//end displayResponse

////////END of outdatedBrowser function
};


//Grafikfabriken Addon
var gfOutdatedBrowser = function(){

    this.cookie_name = 'outdated_browser';
    this.cookie = new gfCookie();

    this.onload = function(func) {

        var oldonload = window.onload;

        if (typeof window.onload != 'function') {
            window.onload = func;
        } else {
            window.onload = function () {
                if (oldonload) {
                    oldonload();
                }
                func();
            };
        }

    };

    this.onload(function(){

        var cookie = this.cookie.get(this.cookie_name);

        if(cookie === ''){
            outdatedBrowser({
                lowerThan: 'IE11',
                languagePath: ' '
            });
        }
    });


};


var gfCookie = function(){

    /**
     * Get cookie
     */
    this.get = function (sName) {
        var oCrumbles = document.cookie.split(';');
        for (var i = 0; i < oCrumbles.length; i++) {
            var oPair = oCrumbles[i].split('=');
            var sKey = decodeURIComponent(oPair[0].trim());
            var sValue = oPair.length > 1 ? oPair[1] : '';
            if (sKey == sName) {
                return decodeURIComponent(sValue);
            }
        }
        return '';
    };
    
    /**
     * Set cookie
     */
    this.set = function (sName, sValue, options) {
        //oDate.setYear(oDate.getFullYear()+1);
        var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);

        // Shorthand: options === expires date
        if (options && options instanceof Date) {
            options = {
                expires: options
            };
        }
        // Longhand: options object
        if (options && typeof options == 'object') {
            if (options.expires) {
                sCookie += '; expires=' + options.expires.toGMTString();
            }
            if (options.path) {
                sCookie += '; path=' + options.path.toString();
            }
            if (options.domain) {
                sCookie += '; domain=' + options.domain.toString();
            }
            if (options.secure) {
                sCookie += '; secure';
            }
        }
        document.cookie = sCookie;
    };
    
    /**
     * Remove cookie
     */
    this.remove = function (sName, options) {
        if (!options) {
            var options = {};
        }
        options.expires = new Date();
        setCookie(sName, '', options);
    };


    return this;

};


gfOutdatedBrowser();